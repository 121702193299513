.event-map-container {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: white;
    border: 0.5px solid rgb(128, 128, 128);
    overflow: hidden;
    min-height: 250px;
}

@media screen and (max-width: 1000px) {
    .event-map-container {
        max-height: 250px;
        min-width: 350px;
    }
  }

.map-image-container {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
}

.map-svg {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

.map-button {
    padding: 5px;
    border: 1px solid black;
    border-radius: 5px;
    font-size: 30px;
    cursor: pointer;
    background-color: white;
    margin-bottom: 5px;
}

.map-button:hover {
    background-color: rgb(245, 245, 245)
}
