.suggestions-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.category-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.suggestion-card-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;
    flex-basis: 0;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-right: 30px;

}

.suggestion-card {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.5px solid gray;

    height: 180px;
    border-radius: 20px;
    background: linear-gradient(to bottom, #444e5b, #000000);

    font-family: 'Avenir Next', sans-serif;
    font-size: 24px;
    font-weight: 500;
    color: white;
    text-align: center;

    cursor: pointer;
}

.suggestion-text-container {
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
}

.suggestion-card:hover{
    background: linear-gradient(to bottom, rgba(68, 78, 91, 0.9), rgba(0, 0, 0, 0.9));
}

.suggestion-card-container:last-child {
    margin-right: 0;
  }

@media screen and (max-width: 768px) {
    .suggestion-card-container {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .suggestion-card {
        height: 140px;
    }

    .suggestion-text-container {
        display: flex;
        flex-direction: column;
        padding: 5px 5px;
    }
}
