.date-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80px;
    min-width: 80px;
    border: 1px solid gray;
    border-radius: 15px;
    margin-right: 30px;

}

.full-date-block {
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80px;
    min-width: 100px;
    border: 1px solid gray;
    border-radius: 15px;
    padding: 10px;
    margin-left: 10px;
}
  
.date-day {
    color: black;
    font-family: 'Avenir Next', sans-serif;
    font-size: 36px;
    font-weight: 600;
    margin: -5px 0;
}

.date-day-s {
    color: black;
    font-family: 'Avenir Next', sans-serif;
    font-size: 30px;
    font-weight: 600;
    margin: -5px 0;
}

@media screen and (max-width: 768px) {
    .date-block {
        margin-right: 15px;
    }
}



