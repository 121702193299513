.row {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  width: 100%;
}

.col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.justify-start {
  justify-content: start;
}

.justify-end {
  justify-content: flex-end;
}




.align-end {
  align-items: flex-end;
}

.space-between {
  justify-content: space-between;
}

.space-around {
  justify-content: space-around;
}

.left {
  flex: 1;
  display: flex;
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.relative {
  position: relative;
}

.hide-password {
  font-size: 20px;
  cursor: pointer;
  right: 15px;
}

.absolute {
  position: absolute;
}

.container-main {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 100%; */
  padding: 20px 0px;
}

.container-view {
  display: flex;
  /* width: 100vw; */
  height: calc(100vh - 100px);
  padding-top: 100px;
}

.container-info {
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 600px);
  padding-top: 100px;
  background-color: rgb(245, 245, 245);

}

.content-main {
  width: 1100px;
  padding: 10px 20px;
}

.content-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 50px 0px;
  width: 800px;
  padding: 0px 20px;
  /* padding-top: 30px; */
}

.full-width {
  width: 100%;
}

.pad {
  padding: 20px !important;
}

.pad-v {
  padding: 20px 0px;
}

.pad-h {
  padding-right: 20px;
  padding-left: 20px;
}

.margin-v {
  margin: 5px 0px;
}

.tall {
  min-height: 600px;
}

.gap-xl {
  min-width: 200px;
}

.gap-l {
  min-width: 100px;
}

.break-s {
  min-height: 10px;
}

.gap {
  min-width: 40px;
}

.gap-m {
  min-width: 20px;
}

.gap-s {
  min-width: 10px;
}

.white {
  background-color: white;
}

.no-display {
  display: none
}

.no-margin {
  margin: 0;
}

.divider {
  border: none;
  height: 1px;
  width: 100%;
  background-color: gray;
}

.divider-margin {
  border: none;
  height: 1px;
  width: 100%;
  margin: 20px 0px;
  background-color: gray;
}


.border-bottom {
  /* border-bottom: 1.5px solid gray; */
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  z-index: 0;
}

.border-top {
  /* border-bottom: 1.5px solid gray; */
  box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.25);
  z-index: 0;
}


a {
  text-decoration: none;
}




.card {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  /* border: 1.5px solid gray; */
  border-radius: 20px;
  background-color: white;
  padding: 30px 40px;
  margin-bottom: 30px;
  box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.15);
}

.card-row {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-direction: row;
  border-radius: 20px;
  background-color: white;
  padding: 30px 40px;
  margin-bottom: 30px;
  box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.15);
}

.card-top {
  margin-top: 30px;
}

.card-item {
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
}

.card-list {
  padding: 10px 10px;
  border-radius: 10px;
  border: 1px solid gray;
}

.max-height-m {
  max-height: 400px;
  overflow-y: auto;
}

.filter-button {
  color: black;
  font-family: 'Avenir Next', sans-serif;
  font-size: 18px;
  font-weight: 500;
  min-height: 36px;

  padding: 5px 20px;
  border: 1px solid gray;
  border-radius: 20px;
  background-color: white;

  transition: background-color 0.3s ease;
  cursor: pointer;
}

.filtered {
  background-color: black;
  border: 1px solid black;
  color: white;
}

.filter-button:hover {
  background-color: rgb(245, 245, 245);
  color: black;
}

.filtered:hover {
  background-color: rgba(0, 0, 0, 0.8);
  /* border: 1px solid black; */
  color: white;
}

.action-button {
  color: white;
  font-family: 'Avenir Next', sans-serif;
  font-size: 18px;
  font-weight: 600;

  padding: 5px 20px;
  border-radius: 20px;
  border: 1px solid #278dc7;
  background-color: #278dc7;

  transition: background-color 0.3s ease;
  cursor: pointer;
}


.action-button:hover {
  /* background-color: #5aaad8; */
  background-color: #5aaad8;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.1);
}

.action-button-full {
  font-family: 'Avenir Next', sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: white;
  background-color: #278dc7;

  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;

  transition: background-color 0.3s ease;
  cursor: pointer;
}

.action-button-full:hover {
  background-color: #5aaad8;
}

.logout-button {
  color: white;
  font-family: 'Avenir Next', sans-serif;
  font-size: 18px;
  font-weight: 600;

  /* margin-right: 10px; */
  padding: 5px 20px;
  border: 1px solid #e23f3f;
  border-radius: 20px;
  background-color: #e23f3f;

  transition: background-color 0.3s ease;
  cursor: pointer;
}

.logout-button:hover {
  background-color: #e57c7c;
  /* color: black; */
}

.back-icon {
  color: black;
  font-size: 24px;
  margin-right: 15px;
  /* border: 1px solid #000000;
  padding: 5px 10px;
  border-radius: 10px; */

  cursor: pointer;
  /* transition: color 0.3s ease; */
}

.carrot-icon {
  color: black;
  font-size: 18px;
  margin-left: 10px;
  margin-right: -5px;
  margin-bottom: -4px;

  cursor: pointer;
  /* transition: color 0.3s ease; */
}

.carrot-icon-selected {
  color: white;
  font-size: 18px;
  margin-left: 10px;
  margin-right: -5px;
  margin-bottom: -4px;

  cursor: pointer;
  /* transition: color 0.3s ease; */
}

/* .back-icon:hover {
  color: #75b4db;
} */



.input {
  font-family: 'Avenir Next', sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: black;
  background-color: white;
  width: 100%;
  box-sizing: border-box;

  padding: 10px;
  border-radius: 5px;
  border: 1px solid gray;
}

.input-error {
  border: 1.5px solid #e23f3f;
}

.input:invalid {
  color: #808080;
  /* Gray color for the placeholder text */
}

.input-small {
  display: flex;
  border-radius: 10px;
  border: 0.5px solid gray;
  padding: 0px 10px;
  height: 40px;
  margin: 2px 0px;
}

.input-search-results {
  width: calc(100% - 20px);
  max-height: 500px;
  overflow-y: auto;
  border: 0.5px solid gray;
  margin-top: -10px;
  padding: 10px;
  border-radius: 5px;

  background-color: white;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);

  position: absolute;
  z-index: 100;
}

.checkbox {
  height: 14px;
  width: 14px;
  margin-left: 0;
  margin-right: 8px;
  cursor: pointer;
}







/* TEXT CLASSES*/
.mail-to {
  color: inherit;
  text-decoration: none;
}

.mail-to:hover {
  color: #75b4db;
}

.markdown-body {
  font-family: 'Avenir Next', sans-serif;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.hero-title {
  font-family: 'Futura', sans-serif;
  color: white;
  font-size: 42px;
  font-weight: 900;
  text-align: center;
  max-width: 90vw;
}

.hero-subtitle {
  font-family: 'Avenir Next', sans-serif;
  color: white;
  font-size: 24px;
  font-weight: 00;
  text-align: center;
  max-width: 90vw;
}

.text-xl {
  font-family: 'Avenir Next', sans-serif;
  color: black;
  font-size: 36px;
  font-weight: 700;

  padding: 50px 0px;
}

.text-l {
  font-family: 'Avenir Next', sans-serif;
  color: black;
  font-size: 28px;
  font-weight: 700;

  padding: 5px 0px;
}

.text-m {
  font-family: 'Avenir Next', sans-serif;
  color: black;
  font-size: 24px;
  font-weight: 700;
}

.text-s-bold {
  font-family: 'Avenir Next', sans-serif;
  color: black;
  font-size: 20px;
  font-weight: 700;
}

.text-s {
  font-family: 'Avenir Next', sans-serif;
  color: black;
  font-size: 20px;
  font-weight: 600;
}

.text-s-light {
  font-family: 'Avenir Next', sans-serif;
  color: black;
  font-size: 20px;
  font-weight: 400;
}

.item-title {
  color: black;
  text-align: left;
  font-family: 'Avenir Next', sans-serif;
  font-size: 18px;
  font-weight: 700;
}

.text-xs-bold {
  font-family: 'Avenir Next', sans-serif;
  color: black;
  font-size: 16px;
  font-weight: 600;
}

.text-xs {
  font-family: 'Avenir Next', sans-serif;
  color: black;
  font-size: 16px;
  font-weight: 500;
}

.text-xs-light {
  font-family: 'Avenir Next', sans-serif;
  color: black;
  font-size: 16px;
  font-weight: 400;
}

.text-xxs {
  font-family: 'Avenir Next', sans-serif;
  color: black;
  font-size: 14px;
  font-weight: 400;
}


.text-xs-link {
  font-family: 'Avenir Next', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #278dc7;

  transition: color 0.3s ease;
  cursor: pointer;
}

.text-xs-important {
  font-family: 'Avenir Next', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #278dc7;
}

.text-xs-success {
  font-family: 'Avenir Next', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #33ab51;
}

.text-xs-pending {
  font-family: 'Avenir Next', sans-serif;
  font-size: 16px;
  font-weight: 600;
  /* color: #e2ac3f; */
  color: #278dc7;
}

.text-xs-error {
  font-family: 'Avenir Next', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #e23f3f;
}

.text-xs-link:hover {
  color: #5aaad8;
}

.file-name {
  max-width: 300px;
  /* Adjust this value as needed */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.file-name-small {
  max-width: 150px;
  /* Adjust this value as needed */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tooltip {
  position: relative;
  display: inline-block;
  margin-left: 5px;
}

.tooltip .tooltip-text {
  visibility: hidden;
  width: 150px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position above the tooltip */
  left: 50%;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.tooltip .fa-info-circle {
  cursor: pointer;
  color: #888;
}





.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #333;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin: 40px auto;
  animation: spin 1s linear infinite;
  transform: none;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  padding: 10px;
  border-bottom: 0.5px solid gray;

  font-family: 'Avenir Next', sans-serif;
  color: white;
  font-size: 14px;
  font-weight: 400;
}

@media screen and (max-width: 768px) {

  .input {
    font-size: 16px;
    padding: 8px;
  }

  .action-button-full {
    font-size: 18px;
    padding: 8px;
  }



  .hero-title {
    font-size: 28px;
    margin-top: 20px;
  }

  .hero-subtitle {
    font-size: 20px;
  }

  .item-title {
    color: black;
    text-align: left;
    font-family: 'Avenir Next', sans-serif;
    font-size: 16px;
    font-weight: 700;
  }

  .text-xl {
    font-size: 30px;
    font-weight: 00;

    padding: 30px 0px;
  }

  .text-l {
    font-size: 24px;
  }

  .text-s-bold {
    font-family: 'Avenir Next', sans-serif;
    color: black;
    font-size: 18px;
    font-weight: 700;
  }

  .card {
    padding: 15px 25px;
  }

  .checkbox {
    min-height: 20px;
    min-width: 20px;
    margin: 5px 0px;
    margin-left: 0px;
    margin-right: 16px;
  }

  .gap {
    min-width: 20px;
  }

  .gap-m {
    min-width: 15px;
  }


  .filter-button {
    font-size: 16px;
    padding: 4px 16px;
    min-height: 0px;
  }

  .action-button {
    font-size: 16px;
    padding: 4px 16px;
  }

  .logout-button {
    font-size: 16px;
    padding: 4px 16px;
  }

  .card-row {
   flex-direction: column;
  }

  .container-view {
    height: calc(100vh - 70px);
    padding-top: 70px;
  }

  .container-info {
    min-height: calc(100vh - 600px);
    padding-top: 50px;
    background-color: white;
  }

  .divider-margin {
    border: none;
    height: 0.5px;
    width: 100%;
    margin: 10px 0px;
    background-color: gray;
  }


}

@media screen and (max-width: 1000px) {
  .container-view {
    flex-direction: column;
  }

  .back-icon {
    font-size: 18px;
  }
}
