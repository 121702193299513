.checkout-left-column {
    flex: 3;
    display: flex;
    flex-direction: column;
    padding: 40px 0px;
    margin-right: 20px;
}

.checkout-right-column {
    position: sticky;
    top: 110px;
    flex: 2;
    display: flex;
    flex-direction: column;
    padding: 40px 0px;
    margin-left: 20px;
}
