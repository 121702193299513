
.navbar-container {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 100px;
    width: 100%;

    position: fixed;
    transition: background-color 0.3s ease;

    z-index: 9999;
}


.mobile-menu-text {
    font-family: 'Futura', sans-serif;
    font-size: 30px;
    color: white;
    font-weight: 400;
    padding: 10px 0px;
    cursor: pointer;
    margin-top: 10px;
}

.mobile-menu-text:hover {
    color: #75b4db;
}

.mobile-submenu-text {
    font-family: 'Avenir Next', sans-serif;
    font-size: 22px;
    color: white;
    font-weight: 500;
    padding: 10px 0px;
    cursor: pointer;
}

.mobile-submenu-text:hover {
    color: #75b4db;
}

.mobile-performer-text {
    font-family: 'Avenir Next', sans-serif;
    font-size: 18px;
    color: white;
    font-weight: 400;
    padding: 10px 0px;
    cursor: pointer;
}

.mobile-performer-text:hover {
    color: #75b4db;
}

.mobile-menu-button {
    font-family: 'Avenir Next', sans-serif;
    color: white;
    font-size: 18px;
    font-weight: 500;

    margin: 50px 20px;
    background-color: transparent;
    border: 1px solid white;
    border-radius: 10px;
    padding: 8px;
    box-sizing: border-box;
    text-align: center;
    width: 60vw;
    max-width: 600px;

    cursor: pointer;
    transition: background-color 0.3s ease;
}


.mobile-menu-button:hover {
    background-color: rgba(119, 136, 152, 0.3);
}


.navbar-solid {
    background-color: black;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.4);
}

.navbar-logo {
    position: relative;
    display: inline-block;
    width: 150px;
    height: 50px;
    margin-right: 20px;
    margin-bottom: 3px;
    background-image: url('/src/assets/714-logo.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.navbar-logo::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('/src/assets/714-logo-hover.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.navbar-logo:hover::before {
    opacity: 1;
}

.navbar-item {
    font-family: 'Avenir Next', sans-serif;
    color: white;
    font-size: 20px;
    font-weight: 500;

    padding: 20px;
    position: relative;

    cursor: pointer;
    transition: color 0.3s ease;
}

.navbar-item:hover {
    color: #75b4db;
}


.navbar-button {
    font-family: 'Avenir Next', sans-serif;
    color: white;
    font-size: 20px;
    font-weight: 400;

    background-color: transparent;
    border: 1px solid white;
    border-radius: 20px;
    padding: 5px 20px;
    margin-right: 5px;

    cursor: pointer;
    transition: background-color 0.3s ease;
}


.navbar-button:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.navbar-icon-s {
    color: white;
    font-size: 18px;

    cursor: pointer;
    transition: color 0.3s ease;
}


.navbar-icon-s:hover {
    color: #75b4db;
}


.navbar-icon {
    color: white;
    font-size: 24px;

    cursor: pointer;
    transition: color 0.3s ease;
    margin-right: 5px
}


.navbar-icon:hover {
    color: #75b4db;
}

.navbar-icon-l {
    color: white;
    font-size: 30px;

    cursor: pointer;
    transition: color 0.3s ease;
}


.navbar-icon-l:hover {
    color: #75b4db;
}


@media screen and (max-width: 768px) {
    .navbar-logo {
        width: 130px;
    }

    .navbar-container {
        height: 70px;
    }

}
