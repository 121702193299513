.footer-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 500px;
    background-color: black;
}

.footer-top {
    display: flex;
    color: white;
    font-family: 'Avenir Next', sans-serif;
    font-size: 20px;
    font-weight: 400;
    text-align: left;
    margin: 0px 20px;
    margin-top: 10px;
}

.footer-divider {
    border: none;
    height: 0.5px;
    margin: 30px 0px;
    width: 100%;
    background-color: #ffffff;
}

.footer-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    text-align: left;
    width: 100%;
    max-width: 350px;
    margin: 0px 20px;
}

.footer-title {
    font-family: 'Futura', sans-serif;
    font-size: 28px;
    color: white;
    font-weight: 400;
}

.footer-link {
    font-family: 'Futura', sans-serif;
    font-size: 28px;
    color: white;
    font-weight: 400;

    transition: color 0.3s ease;
    cursor: pointer;
}

.footer-link:hover {
    color: #75b4db;
}

.footer-text {
    font-family: 'Avenir Next', sans-serif;
    color: white;
    font-size: 18px;
    font-weight: 400;
}

.footer-text-xs {
    font-family: 'Avenir Next', sans-serif;
    color: white;
    font-size: 16px;
    font-weight: 400;
}

.footer-text-xs-link {
    font-family: 'Avenir Next', sans-serif;
    color: white;
    font-size: 16px;
    font-weight: 400;
}

.footer-text-xs-link:hover {
    color: #75b4db;
}

.social-logo {
    margin-top: 10px;
    height: 30px;
    width: 30px;
}

@media screen and (max-width: 768px) {
    .footer-column {
        margin-right: 0px;
        margin-bottom: 40px;
        margin-top: 20px;
        width: 100%;
    }

    .footer-top {
        margin: 0px 20px;
        margin-top: 60px;
        margin-bottom: 30px;

    }

}
