.dropdown-menu {
    display: flex;
    flex-direction: column;
    /* max-width: 300px; */
    margin-top: 15px;
    padding: 15px;
    border-radius: 15px;
    background-color: white;
    border: 0.5px solid gray;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
  
    position: absolute;
    z-index: 100;

    /* max-height: 200px; */
  }

  .dropdown-scroll {
    min-width: 250px;
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
  }



  
  .dropdown {
    margin-top: 15px;
    position: absolute;
    z-index: 10000;
  }
  
  .right-aligned {
    right: 0px;
  }
  
  .dropdown-item {
    display: flex;
    font-family: 'Avenir Next', sans-serif;
    color: black;
    font-size: 20px;
    font-weight: 400;
    padding: 5px 15px;
    border-radius: 10px;
    white-space: nowrap;
    width: calc(100% - 30px);
  }
  
  .dropdown-item:hover {
    background-color: rgb(0, 0, 0, 0.1);
  }

  .dropdown-selected {
    background-color: rgb(240, 240, 240);
  }
  
  .dropdown-input {
    font-family: 'Avenir Next', sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: black;
    background-color: white;
    /* width: calc(100% - 20px); */
    width: 280px;
  
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 0.5px solid gray;
  }

  .dropdown-logout-button {
    font-family: 'Avenir Next', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: white;
    background-color: #e23f3f;

    box-sizing: border-box;
    text-align: center;
  
    width: 100%;
    margin: 5px 0px;
    padding: 10px;
    border: none; 
    border-radius: 5px;
  
    transition: background-color 0.3s ease;
    cursor: pointer;
  }
  
  .dropdown-logout-button:hover {
    background-color: #e57c7c;
  }
  
  .dropdown-action-button {
    font-family: 'Avenir Next', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: white;
    background-color: #278dc7;

    box-sizing: border-box;
    text-align: center;
  
    width: 100%;
    margin: 5px 0px;
    padding: 10px;
    border: none; 
    border-radius: 5px;
  
    transition: background-color 0.3s ease;
    cursor: pointer;
  }
  
  .dropdown-action-button:hover {
    background-color: #5aaad8;
  }
  
  .slider-value {
    flex: 1;
    border: 0.5px solid gray;
    border-radius: 10px;
    margin: 0px 10px;
    margin-bottom: 10px;
    padding: 5px;
    
    width: 100px;
  
    color: black;
    font-family: 'Avenir Next', sans-serif;
    text-align: left;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
  }
  
  .select-item {
    display: flex;
    align-items: center;
    font-family: 'Avenir Next', sans-serif;
    color: black;
    font-size: 20px;
    font-weight: 400;
    padding: 5px 10px;
    border-radius: 10px;

    white-space: nowrap;
  
    cursor: pointer;
  }
  
  .select-item:hover {
    background-color: rgb(240, 240, 240);
  }

  .selected-item {
    /* border: 0.5px solid black; */
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.25);

  }

  .datepicker {
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
    border-radius: 15px;
  }

  .datepicker .react-datepicker__header {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;

  }

  .datepicker .react-datepicker__current-month {
    font-family: 'Avenir Next', sans-serif;
    font-weight: 700;
  }

  .datepicker .react-datepicker__day-name {
    font-family: 'Avenir Next', sans-serif;
    font-weight: 600;
  }

  .datepicker .react-datepicker__day {
    font-family: 'Avenir Next', sans-serif;
    font-weight: 500;
  }

  .datepicker .react-datepicker__day--in-range {
    background-color: #278dc7;
    color: white;
  }



  /* Mobile Calendar*/
.mobile-filter-menu{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 100000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  border: 1px solid #bbbbbb;
  box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.2);
}

.mobile-calendar.datepicker {
  width: 95%;
  max-width: 500px;
  margin: 20px;
  height: auto;
  border: none;
  box-shadow: none;
  border-radius: 0px;
}

.mobile-calendar.datepicker .react-datepicker {
  width: 100%;
}

.mobile-calendar.datepicker .react-datepicker__header {
  border-radius: 0px;
  background-color: white;

}

.mobile-calendar.datepicker .react-datepicker__month-container {
  width: 100%;
}

.mobile-calendar.datepicker .react-datepicker__day {
  width: 100%;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  margin: 0px;
}

.mobile-calendar.datepicker .react-datepicker__day-names {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  width: 100%;
}

.mobile-calendar.datepicker .react-datepicker__day-name {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 15px 0;
  font-size: 14px;
  font-weight: 400;
}

.mobile-calendar.datepicker .react-datepicker__week {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}
