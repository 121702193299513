.search-hero-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: 600px;

    background-image: url('../../assets/search-background.jpg');
    background-size: cover;
    background-position: center bottom;
}

.results-hero-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: 325px;

    background-image: url('../../assets/search-background.jpg');
    background-size: cover;
    background-position: center bottom;
}

@media screen and (max-width: 768px) {

    .search-hero-container {
        height: 325px;
        background-image: url('../../assets/search-background-mobile.jpg');
    }

    .results-hero-container {
        background-image: url('../../assets/search-background-mobile.jpg');
    }
  }
  