.event-listings-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 600px;
    min-width: 600px;
 }

 @media screen and (max-width: 1000px) {
  .event-listings-container {
    max-width: 100vw;
    min-width: 350px;
 }
}

.sticky-listings-header {
  position: sticky;
  top: 70px;
  background-color: white;
}

.event-listings-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 5px 20px;
  box-sizing: border-box;
  overflow-y: auto;
  background-color: rgb(245, 245, 245);
}

.listing-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  border-radius: 10px;

  width: 100%;
  box-sizing: border-box;
  padding: 10px 15px;
  margin-bottom: 15px;

  transition: background-color 0.3s ease;
  cursor: pointer;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.15);

}

.listing-row:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.back-button {
  margin-right: 15px;
  padding: 5px 10px;
  border: 1px solid black;
  border-radius: 5px;
  background-color: transparent;

  font-size: 20px;
  cursor: pointer;
}

.back-button:hover {
  background-color: rgb(230, 230, 230);
}

.listing-info-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 15px;
  margin-bottom: 15px;
}
