.banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 250px;
    padding: 50px 50px;
    box-sizing: border-box;

    background: linear-gradient(to bottom right, #1861b0, #81d161);

    color: white;
    margin-bottom: 70px;
    margin-top: 10px;
    text-align:center;
  }

  .banner-title {
    font-family: 'Futura', sans-serif;
    font-size: 48px;
    font-weight: 700;
    margin-bottom: 5px;
  }

  .banner-description {
    font-family: 'Avenir Next', sans-serif;
    font-size: 24px;
    font-weight: 400;
  }

  @media screen and (max-width: 768px) {
    .banner-title {
      font-size: 40px;
    }

    .banner-description {
      font-size: 20px;
    }

    .banner {
      min-height: 300px;
    }
}

  @keyframes slide-in {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  .slide-in {
    animation: slide-in 0.5s ease-in-out;
  }

  .banner-button {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 1px solid white;
    border-radius: 20px;
    padding: 10px 20px;
    margin-top: 20px;

    font-family: 'Avenir Next', sans-serif;
    font-size: 20px;
    font-weight: 500;
    color: white;

    cursor: pointer
}

.banner-button:hover {
    background-color: rgba(255, 255, 255, 0.15)
}
