.hero-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    height: 550px;

    background-color: rgb(135, 150, 166);
    background-size: cover;
    background-position: center center;
    z-index: -1;
}

.hero-container::after {
    content: '';
    position: absolute;

    height: 100%;
    width: 100%;

    background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.2));
    z-index: -1;
}

.favorites-button {
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: 'Avenir Next', sans-serif;
    color: white;
    font-size: 20px;
    font-weight: 400;

    background-color: transparent;
    border: 1px solid white;
    border-radius: 20px;
    padding: 5px 20px;


    position: relative;
    cursor: pointer;
    z-index: 1;
}

.favorites-button:hover {
    background-color: rgba(255, 255, 255, 0.15)
}

.favorites-icon {
    color: white;
    font-size: 20px;
    margin-right: 10px;

    cursor: pointer;
}

@media screen and (max-width: 768px) {

    .hero-container {
        height: 325px;
    }
  }

