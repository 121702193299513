.admin-column {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.admin-checkbox {
    height: 16px;
    width: 16px;
    cursor: pointer;
  }

.admin-search {
    display: flex;
    flex-grow: 1;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 5px 10px;
    background-color: white;

    color: black;
    font-family: 'Avenir Next', sans-serif;
    font-size: 16px;
    font-weight: 500;
}

.admin-input {
    display: flex;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 5px 10px;
    background-color: white;

    color: black;
    font-family: 'Avenir Next', sans-serif;
    font-size: 16px;
    font-weight: 500;
}

.admin-action-button {
    border-radius: 5px;
    padding: 5px 20px;
    background-color: #278dc7;
    border: 1px solid #278dc7;

    color: white;
    font-family: 'Avenir Next', sans-serif;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}

.gray-bg {
    background-color: #f4f4f4;
}

.admin-delete-button {
    border-radius: 5px;
    padding: 5px 20px;
    background-color: white;
    border: 1px solid #e23f3f;
    background-color: white;

    color: #e23f3f;
    font-family: 'Avenir Next', sans-serif;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}

.admin-delete-button:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.admin-small-button {
    border-radius: 5px;
    padding: 2px 8px;
    background-color: white;
    border: 1px solid gray;
    margin: 2px 0px;
    background-color: white;

    color: black;
    font-family: 'Avenir Next', sans-serif;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
}

.admin-small-button:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.admin-delete-small-button {
    border-radius: 5px;
    padding: 2px 8px;
    background-color: white;
    border: 1px solid #e23f3f;
    margin: 2px 0px;
    background-color: white;

    color: #e23f3f;
    font-family: 'Avenir Next', sans-serif;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
}

.admin-delete-small-button:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.admin-action-button:hover {
    background-color: #5aaad8;
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.1);
}

.admin-button {
    border-radius: 5px;
    padding: 5px 20px;
    border: 1px solid gray;
    background-color: white;

    color: black;
    font-family: 'Avenir Next', sans-serif;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
}

.admin-button:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.admin-filtered {
    background-color: black;
    border: 1px solid black;
    color: white;
}

.admin-filtered:hover {
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
}

.admin-data-grid .MuiDataGrid-cell {
    color: black;
    font-family: 'Avenir Next', sans-serif;
    font-size: 14px;
    font-weight: 300;

}

.admin-data-grid .MuiDataGrid-columnHeaders {
    color: black;
    background-color: gray;
    font-family: 'Avenir Next', sans-serif;
    font-size: 16px;
    font-weight: 500;
}

.admin-data-grid .MuiDataGrid-row {
    background-color: white;
}

.admin-data-grid .MuiDataGrid-row:hover {
    cursor: pointer
}
