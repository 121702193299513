.search-form {
    display: flex;
    align-items: center;
    width: 90vw;
    max-width: 600px;
    box-sizing: border-box;
    background-color: rgba(119, 136, 152, 0.3);
    padding: 10px;
    border-radius: 15px;
    border: 1px solid white;

    transition: background-color 0.3s ease;
}

.focused-form {
    background-color: white;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
    border: 1px solid gray;
}

.search-input {
    flex: 1;
    font-family: 'Avenir Next', sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: white;

    margin-top: 3px;
    background: none;
    border: none;
    outline: none;

    transition: color 0.3s ease;
}

.focused-input {
    color: black;
}

.search-input:focus::placeholder {
    color: rgb(100, 100, 100);
}


.search-input::placeholder {
    color: white;
}

.search-loading-spinner {
    border: 2px solid rgba(255, 255, 255, 0.1);
    border-left-color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin: 10px;
    margin-right: 20px;
    animation: spin 1s linear infinite;
    z-index: 1000;
}

.search-loading-spinner-nav {
    border: 2px solid rgba(255, 255, 255, 0.1);
    border-left-color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin: 10px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
      transform: rotate(360deg);
    }
}

.focused-search-loading-spinner {
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-left-color: #278dc7;
}


.search-icon {
    color: white;
    font-size: 24px;
    margin: 10px;
    margin-right: 20px;

    cursor: pointer;
    transition: color 0.3s ease;
    z-index: 1000;
}

.focused-icon {
    color: #278dc7;
}

.search-menu {
    display: flex;
    flex-direction: column;

    width: 90vw;
    max-width: 600px;
    box-sizing: border-box;
    padding: 15px;
    padding-top: 10px;
    margin-top: -15px;
    border-radius: 10px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;

    background-color: white;
    border: 0.5px solid gray;
    border-top: none;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);

    position: absolute;
    z-index: 1;
}


.menu-title {
    padding: 10px 10px;
    padding-top: 15px;
    font-family: 'Avenir Next', sans-serif;
    color: black;
    font-size: 20px;
    font-weight: 700;
}

.menu-item {
    flex: 1;
    flex-direction: column;
    display: flex;
    font-family: 'Avenir Next', sans-serif;
    color: black;
    font-size: 20px;
    font-weight: 400;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
}

.center-text {
    text-align: center;
}

.menu-item-title {
    font-family: 'Avenir Next', sans-serif;
    color: black;
    font-size: 20px;
    font-weight: 500;
}

.menu-item-subtitle {
    font-family: 'Avenir Next', sans-serif;
    color: black;
    font-size: 14px;
    font-weight: 400;
}

.menu-item:hover {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.15);
}

.menu-item-selected {
    background-color: rgb(252, 238, 226);
}

.search-form-nav {
    display: flex;
    align-items: center;
    width: 400px;
    box-sizing: border-box;
    background-color: rgba(119, 136, 152, 0.3);
    padding: 0px 3px;
    border-radius: 20px;
    margin-left: 10px;
    transition: background-color 0.3s ease;
}

.focused-form-nav {
    background-color: white;

}

.search-icon-nav {
    color: white;
    font-size: 24px;
    margin: 10px;

    cursor: pointer;
    transition: color 0.3s ease;
}

.focused-icon-nav {
    color: #278dc7;
}

.search-input-nav {
    flex: 1;
    font-family: 'Avenir Next', sans-serif;
    font-size: 18px;
    font-weight: 400;
    color: white;

    background: none;
    border: none;
    outline: none;
}

.search-form-consignment {
    background-color: white;
    width: 100%;
    box-sizing: border-box;
    padding: 2px;
    border-radius: 5px;
    border: 1px solid gray;
}


.search-input-consignment {
    flex: 1;
    font-family: 'Avenir Next', sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: black;

    margin-left: -5px;
    margin-top: 2px;
    background: none;
    border: none;
    outline: none;
}


.search-input-nav::placeholder {
    color: white;
}

.search-input-nav:focus::placeholder {
    color: black
}

.focused-input-nav {
    color: black
}

.search-menu-nav {
    display: flex;
    flex-direction: column;

    width: 400px;
    box-sizing: border-box;
    margin-top: 10px;
    padding: 15px 20px;
    border-radius: 20px;
    margin-left: 10px;


    background-color: white;
    border-top: none;

    font-family: 'Avenir Next', sans-serif;
    color: black;
    font-size: 20px;
    font-weight: 500;

    position: absolute;
    z-index: 0;

    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);

}

@media screen and (max-width: 768px) {

    .search-form {
        border-radius: 10px;
        border: 1px solid white;
        padding: 8px;
    }

    .search-icon {

        margin: 5px;
        margin-right: 10px;
    }

    .search-loading-spinner {
        margin: 5px;
        margin-right: 10px;

    }

    .search-input {
        font-size: 16px;
        font-weight: 500;
    }

    .search-menu {
        border: 1px solid white;
        margin-top: -15px;

    }
}
