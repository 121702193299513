.event-bar {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    padding: 15px;
    border-radius: 15px;
    cursor: pointer;
}
.event-bar:hover {
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.15);
}

.no-click:hover {
    box-shadow: none;
    cursor:auto
}

@media screen and (max-width: 768px) {
    .event-bar {
        padding: 10px 0px;
    }

    .event-bar:hover {
        box-shadow: none
    }
}