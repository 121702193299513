.account-left-column {
    display: flex;
    flex-direction: column;
    padding: 40px 0px;
    margin-right: 20px;
}

.account-right-column {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 40px 0px;
    margin-left: 20px;
}
