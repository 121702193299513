.mobile-loading-container {
    width: 100vw;
    height: 100vh;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
}

.mobile-loading-logo {
    width: 70px;
    position: fixed;
}

.mobile-loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid rgb(39, 141, 199, 0.2);
    border-left-color: #278dc7;
    border-top-color: #278dc7;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    margin: 40px auto;
    animation: mobile-spin 1s linear infinite;
    transform: none;
}

@keyframes mobile-spin {
    to {
        transform: rotate(360deg);
    }
}